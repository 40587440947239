.slide{
    display: flex;
    margin:0 auto;
    justify-content: center;
    width:100% !important;
}
.microcos-swiper{
    width: 100%;
    height: 251px !important;
    display: flex;
    margin:51px auto 0;
  }
  .swiper-container{
    --swiper-theme-color: #ff6600;/* 设置Swiper风格 */
    --swiper-navigation-color: #121314;/* 单独设置按钮颜色 */
    --swiper-navigation-size: 12px;/* 设置按钮大小 */
  }
  .microcos-slide-img{
    width: 270px !important;
    height: 133px !important;
    margin-top: 30px !important;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2) !important;
  }
  .microcos-slide-text{
    color:#121314;
    margin-top:5px;
    font-size: 14px;
    word-break: break-all; /*允许在单词内换行*/
    text-align: left;
    text-overflow: -o-ellipsis-lastline; /*css3中webkit内核提供的一个方法类似ellipsis*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /*自适应盒子*/
    -webkit-line-clamp: 2; /*此处为1行,如果是两行就改成2*/
    -webkit-box-orient: vertical;
  }
  .microcos-slide-times{
      color:#979797;
      margin-top:7px;
      font-size: 10px;
  }
  .microcos-slide-a-text{
      text-decoration: none;
      display: block;
      width: 270px;
      margin-left: -12px;
  }
  .swiper-button-prevmic{
      outline: none;
      font-weight:bold;
      position:absolute;
      margin-top:130px;
      margin-left:130px;
      padding:10px
  }