.swiper-news{
    width: 100%;
    display: flex;
  }
 .slide{
     display: flex;
     margin:0 auto;
     justify-content: center;
     width:100% !important;
 }
 .swiper-container{
    --swiper-theme-color: #ff6600;/* 设置Swiper风格 */
    --swiper-navigation-color: #121314;/* 单独设置按钮颜色 */
    --swiper-navigation-size: 12px;/* 设置按钮大小 */
  }
  .news-slide-text{
    margin-top:8px;
  }
  .news-slide-img{
    display:flex;
    align-items:flex-end;
    position:relative;
    right:-30px;
    justify-content: flex-end;
  }
  .news-slide-pic{
   width:250px;
   height:250px;
   border-radius:100%;
   box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2) ;
  }
  .news-slide-text-media{
    font-size: 14px;
    word-break: break-all; /*允许在单词内换行*/
    text-align: left;
    text-overflow: -o-ellipsis-lastline; /*css3中webkit内核提供的一个方法类似ellipsis*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /*自适应盒子*/
    -webkit-line-clamp: 2; /*此处为1行,如果是两行就改成2*/
    -webkit-box-orient: vertical;
  }