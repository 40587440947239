.menu {
    display: inline-block;
  }
  .menu-enter {
    opacity: 0.01;
    transform: translateY(-100%) ;
  }
  .menu-enter-active {
    opacity: 1;
    transform: translateY(0%) ;
    transition: all 300ms ease-out;
  }
  .menu-exit {
    opacity: 1;
    transform: translateY(0%) ;
  }
  .menu-exit-active {
    opacity: 0;
    transform: translateY(-100%) ;
    transition: all 300ms ease-in;
  }