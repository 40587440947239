
  .container{
    width: 100%;
    height: 251px !important;
    display: flex;
    margin:52px auto 0;
  }
 .slide{
     display: flex;
     margin:0 auto;
     justify-content: center;
     width:100% !important;
 }
 .swiper-container{
    --swiper-theme-color: #ff6600;/* 设置Swiper风格 */
    --swiper-navigation-color: #121314;/* 单独设置按钮颜色 */
    --swiper-navigation-size: 12px;/* 设置按钮大小 */
  }